var isClient = typeof window !== 'undefined';
var get = function (name, or) {
    var data = or;
    if (isClient) {
        var dataStorage = localStorage.getItem(name);
        if (dataStorage) {
            data = JSON.parse(dataStorage);
        }
    }
    return data;
};
export var set = function (name, data) {
    if (isClient) {
        localStorage.setItem(name, JSON.stringify(data));
    }
};
export var LocalStorage = {
    get: get,
    set: set,
};

import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Suspense } from "react";
import { Providers, routerApp } from "./app";
import { BeforeLoading } from "./widgets";
import "./app/styles/globals.scss";
var root = document.getElementById("root");
if (!root) {
    throw new Error("root not found");
}
var container = createRoot(root);
container.render(_jsx(Providers, { children: _jsx(Suspense, { fallback: _jsx(BeforeLoading, {}), children: _jsx(RouterProvider, { router: routerApp }) }) }));

import { createStore } from "../../create-store";
var initState = {
    processNodes: [],
    step: 0,
    totalCount: 0,
    speed: 1,
    play: false,
    auth: null,
    isShowMainIstruction: true,
    isShowProcessIstruction: true,
};
export var processStore = createStore({
    initState: initState,
    persistName: 'process',
});

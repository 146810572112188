import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { LayoutHeader } from "../layout-header/LayoutHeader";
import { NotificationList } from "@/widgets/notification";
import { LayoutFooter } from "../layout-footer/LayoutFooter";
import backgroundMain from '../../../../public/assets/images/main-background.png';
import backgroundMainMobile from '../../../../public/assets/images/main-background-modile.png';
import styles from "./MainLayout.module.scss";
var MainLayout = function () {
    // const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(function () {
    }, []);
    // if (isLoading) {
    //   return;
    // }
    return (_jsxs("main", { className: styles.root, children: [_jsx("img", { className: styles.backgroundMain, src: backgroundMain, alt: "Background" }), _jsx("div", { className: styles.imgWrapperMobile, children: _jsx("img", { className: styles.backgroundMainMobile, src: backgroundMainMobile, alt: "Background" }) }), _jsx(LayoutHeader, {}), _jsx(NotificationList, {}), _jsx("div", { className: styles.content, children: _jsx(Outlet, {}) }), _jsx(LayoutFooter, {})] }));
};
export { MainLayout };

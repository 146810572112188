var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useRef, useState } from "react";
var useMediaFiles = function () {
    var fileRef = useRef(null);
    var fileRefUpdate = useRef(null);
    var _a = __read(useState({ file: null, id: '', result: '' }), 2), fileList = _a[0], setFileList = _a[1];
    var addFile = function (result, file) {
        var id = String(Date.now());
        setFileList(function () { return ({ id: id, result: String(result), file: file }); });
    };
    var editFile = function (result, file) {
        var id = String(Date.now());
        setFileList(function () { return ({
            id: String(id),
            file: file,
            result: String(result),
        }); });
    };
    var setFileToBase = function (file, update) {
        try {
            var render_1 = new FileReader();
            render_1.readAsDataURL(file);
            render_1.onloadend = function () {
                if (!render_1.result)
                    return;
                if (update) {
                    editFile(render_1.result, file);
                }
                else {
                    addFile(render_1.result, file);
                }
            };
        }
        catch (error) {
            console.log(error, "Ошибка при загрузке файла!");
        }
    };
    var changeFile = function (e) {
        var file = e.target.files ? e.target.files[0] : false;
        if (!file)
            return;
        setFileToBase(file, false);
    };
    function handleEditFile(e) {
        var file = e.target.files ? e.target.files[0] : false;
        if (!file)
            return;
        setFileToBase(file, true);
    }
    function handleResetFile() {
        setFileList({ file: null, id: '', result: '' });
    }
    return {
        handleEditFile: handleEditFile,
        fileRef: fileRef,
        changeFile: changeFile,
        fileList: fileList,
        fileRefUpdate: fileRefUpdate,
        handleResetFile: handleResetFile,
    };
};
export { useMediaFiles };

import { CONFIG_APP } from "../../config";
import { getToken } from "./utils";
export function fethConfig(method, payload) {
    if (method === void 0) { method = "GET"; }
    var payloadIsFormData = payload instanceof FormData;
    var config = {
        method: method,
        headers: {
            Authorization: "Bearer ".concat(getToken()),
        }
    };
    if (!payloadIsFormData) {
        config.headers["Content-Type"] = 'application/json';
    }
    if (payload) {
        var currentPayload = payloadIsFormData ? payload : JSON.stringify(payload);
        config.body = currentPayload;
    }
    return config;
}
export function fetchUrl(url, params) {
    var mainUrl = new URL(CONFIG_APP.API_ENDPOINT);
    if (url) {
        mainUrl = new URL("".concat(mainUrl, "api/").concat(url));
    }
    if (params) {
        var paramsString = getParamsString(params);
        var searchParams = new URLSearchParams(paramsString);
        mainUrl.search = searchParams.toString();
    }
    return mainUrl.toString();
}
function getParamsString(params) {
    var resultString = '';
    for (var key in params) {
        var value = params[key];
        if (value) {
            resultString += "&".concat(key, "=").concat(value);
        }
    }
    return resultString.slice(1);
}

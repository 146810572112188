import { Notification } from "../model/model";
import { NotificationAdapter } from "../store/adapter";
var NotificationService = /** @class */ (function () {
    function NotificationService() {
        this.notification = new Notification();
        this.notificationAdapter = new NotificationAdapter();
    }
    NotificationService.prototype.activeNotification = function (options) {
        this.notification.getNewNotificatin(options);
        return this.notificationAdapter.setNotification(this.notification);
    };
    NotificationService.prototype.resetNotification = function () {
        return this.notificationAdapter.resetNotification();
    };
    return NotificationService;
}());
export { NotificationService };
export var notificationService = new NotificationService();

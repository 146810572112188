import { METODATA_TITLES, METODATA_TITLES_DINAMIC, } from "../constants/metodata";
var ENV_VARIABLES = {
    BACKEND_URL: BACKEND_URL,
    APP_MODE: APP_MODE,
    REACT_APP_VERSION: REACT_APP_VERSION,
    REACT_APP_STATUS: REACT_APP_STATUS,
    ACCESS_TOKEN_NAME: ACCESS_TOKEN_NAME,
    REFRESH_TOKEN_NAME: REFRESH_TOKEN_NAME,
};
export var CONFIG_APP = {
    METODATA_TITLES: METODATA_TITLES,
    METODATA_TITLES_DINAMIC: METODATA_TITLES_DINAMIC,
    API_ENDPOINT: ENV_VARIABLES.BACKEND_URL,
    ACCESS_TOKEN_COOKIE: ENV_VARIABLES.ACCESS_TOKEN_NAME,
    REFRESH_TOKEN_COOKIE: ENV_VARIABLES.REFRESH_TOKEN_NAME,
    START_PAGE_AFTER_LOGIN: '/photo',
    REACT_APP_VERSION: ENV_VARIABLES.REACT_APP_VERSION,
    REACT_APP_STATUS: ENV_VARIABLES.REACT_APP_STATUS,
    APP_MODE: ENV_VARIABLES.APP_MODE,
    INIT_INTERVAL_STEP: 5000,
};

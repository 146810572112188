import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { CONFIG_APP } from "../config";
var persistMiddleware = function (initState, persistName) {
    if (!persistName) {
        return initState;
    }
    return persist(initState, {
        name: persistName,
        storage: createJSONStorage(function () { return localStorage; }),
    });
};
export var createStore = function (_a) {
    var persistName = _a.persistName, initState = _a.initState;
    return create()(immer(CONFIG_APP.APP_MODE === "dev"
        ? devtools(persistMiddleware(function () { return initState; }, persistName))
        : persistMiddleware(function () { return initState; }, persistName)));
};

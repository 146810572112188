import { LayoutAdapter } from "../store/adapter";
var LayoutController = /** @class */ (function () {
    function LayoutController() {
        this.layoutAdapter = new LayoutAdapter();
    }
    LayoutController.prototype.activeSideMenu = function () {
        return this.layoutAdapter.setSideMenuOpen();
    };
    LayoutController.prototype.hideSideMenu = function () {
        return this.layoutAdapter.setSideMenuClose();
    };
    LayoutController.prototype.setContacts = function (contacts) {
        return this.layoutAdapter.setInitContacts(contacts);
    };
    return LayoutController;
}());
export { LayoutController };
export var layoutController = new LayoutController();

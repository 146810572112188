var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { processStore } from "./store";
var ProcessAdapter = /** @class */ (function () {
    function ProcessAdapter() {
        this.store = processStore;
    }
    ProcessAdapter.prototype.saveTemplateNods = function (nodes) {
        this.store.setState({ processNodes: nodes, step: 0, totalCount: nodes.length - 1 });
    };
    ProcessAdapter.prototype.resetProcess = function () {
        this.store.setState({ processNodes: [], step: 0, totalCount: 0 });
    };
    ProcessAdapter.prototype.updateAuth = function (auth) {
        this.store.setState({ auth: auth });
    };
    ProcessAdapter.prototype.updateTokens = function (_a) {
        var accessToken = _a.accessToken, refreshToken = _a.refreshToken;
        var currentAuth = this.store.getState().auth;
        if (currentAuth) {
            this.store.setState({ auth: __assign(__assign({}, currentAuth), { refreshToken: refreshToken, accessToken: accessToken }) });
        }
    };
    ProcessAdapter.prototype.setSpeed = function (value) {
        this.store.setState({ speed: value });
    };
    ProcessAdapter.prototype.setPlay = function (value) {
        this.store.setState({ play: value });
    };
    ProcessAdapter.prototype.changeStep = function (value) {
        this.store.setState({ step: value });
    };
    ProcessAdapter.prototype.toggleMainInsturction = function (value) {
        this.store.setState({ isShowMainIstruction: value });
    };
    ProcessAdapter.prototype.toggleProcessInsturction = function (value) {
        this.store.setState({ isShowProcessIstruction: value });
    };
    ProcessAdapter.prototype.saveAuth = function (auth) {
        this.store.setState({ auth: auth });
    };
    ProcessAdapter.prototype.getStepsVoiceStrings = function () {
        var nodesList = this.store.getState().processNodes;
        var step = this.store.getState().step;
        return { current: nodesList[step], next: nodesList[step + 1] };
    };
    ProcessAdapter.prototype.getCurrentAndPrevStep = function () {
        var nodesList = this.store.getState().processNodes;
        var step = this.store.getState().step;
        return { currentStep: nodesList[step], prevStep: nodesList[step - 1] };
    };
    ProcessAdapter.prototype.getAdjacentSteps = function () {
        var step = this.store.getState().step;
        var nodesList = this.store.getState().processNodes;
        var adjacentResult = {
            prevSteps: [],
            nextSteps: [],
        };
        for (var i = step - 4; i < nodesList.length; i++) {
            if (i > step + 4)
                break;
            if (i === step)
                continue;
            var element = nodesList[i];
            if (element && i < step) {
                adjacentResult.prevSteps.push(element);
            }
            else if (!element && i < step) {
                adjacentResult.prevSteps.push('0');
            }
            else if (element && i > step) {
                adjacentResult.nextSteps.push(element);
            }
        }
        return adjacentResult;
    };
    ProcessAdapter.prototype.searchStep = function (value) {
        var nodesList = this.store.getState().processNodes;
        var searchNode = nodesList[value - 1];
        if (searchNode) {
            this.store.setState({ step: value - 1 });
            return 'success';
        }
        else {
            return 'error';
        }
    };
    ProcessAdapter.prototype.getRefreshToken = function () {
        var _a;
        return (_a = this.store.getState().auth) === null || _a === void 0 ? void 0 : _a.refreshToken;
    };
    return ProcessAdapter;
}());
export { ProcessAdapter };

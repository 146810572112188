var Notification = /** @class */ (function () {
    function Notification() {
        this.message = "";
        this.status = "error";
        this.timeFrom = "";
    }
    Notification.prototype.getNewNotificatin = function (_a) {
        var message = _a.message, status = _a.status;
        this.status = status;
        this.message = message;
        this.timeFrom = new Date().toString();
    };
    return Notification;
}());
export { Notification };

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { Container } from "@/shared/ui/container/Container";
import HeaderLogo from '../../../../public/assets/images/logo.png';
// import { UserHeaderSvg } from "@/shared/svg/UserHeaderSvg";
// import { processStore } from "@/shared/service/proggress";
import styles from "./LayoutHeader.module.scss";
var LayoutHeader = function () {
    var navigate = useNavigate();
    // const processNodes = processStore(store => store.processNodes);
    return (_jsx(_Fragment, { children: _jsxs("header", { className: styles.header, children: [_jsx("div", { className: styles.blurContainer }), _jsx(Container, { children: _jsx("section", { className: styles.content, children: _jsx("img", { onClick: function () { return navigate('/'); }, className: styles.logoImg, src: HeaderLogo, alt: "Header Logo" }) }) })] }) }));
};
export { LayoutHeader };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { notificationService, notificationStore } from "@/shared";
import styles from "./NotificationList.module.scss";
import { NitificationSuccessSvg } from "@/shared/svg/NitificationSuccessSvg";
import { CloseSvg } from "@/shared/svg/CloseSvg";
import { NotificationErrorSvg } from "@/shared/svg/NotificationErrorSvg";
import { NotificationWarnSvg } from "@/shared/svg/NotificationWarnSvg";
var NotificationList = function () {
    var notificationList = notificationStore(function (state) { return state.notificationList; });
    var timeOut = notificationStore(function (state) { return state.timeOut; });
    var checkClearNotification = function () {
        var currentDate = Date.now();
        var maxTimeNow = timeOut * 1000;
        for (var i = 0; i < notificationList.length; i++) {
            var notDateString = notificationList[i].timeFrom;
            var notNowDate = new Date(notDateString).getTime();
            if (currentDate - notNowDate >= maxTimeNow) {
                notificationService.resetNotification();
            }
        }
    };
    useEffect(function () {
        if (notificationList.length === 0)
            return;
        var timeOut = setInterval(checkClearNotification, 1000);
        return function () {
            clearInterval(timeOut);
        };
    }, [notificationList, timeOut]);
    var handleClose = function () {
        notificationService.resetNotification();
    };
    if (notificationList.length === 0) {
        return null;
    }
    return (_jsx("ul", { className: styles.wrapper, children: notificationList.map(function (notification, index) { return (_jsxs("li", { className: styles.root, children: [_jsxs("div", { className: styles.svg, children: [notification.status === 'success' &&
                            _jsx(NitificationSuccessSvg, {}), notification.status === 'error' &&
                            _jsx(NotificationErrorSvg, {}), notification.status === 'warn' &&
                            _jsx(NotificationWarnSvg, {})] }), _jsxs("div", { className: styles.content, children: [_jsxs("h1", { className: styles.notificationTitle, children: [notification.status === 'success' &&
                                    'Отлично', notification.status === 'error' &&
                                    'Ошибка', notification.status === 'warn' &&
                                    'Предупреждение'] }), _jsx("span", { className: styles.notificationText, children: notification.message })] }), _jsx("button", { onClick: handleClose, className: styles.closeSvg, children: _jsx(CloseSvg, {}) })] }, "".concat(index).concat(notification.timeFrom))); }) }));
};
export { NotificationList };

import { z } from "zod";
import { createResponseSchema } from "@/shared/service";
import { CONFIG_APP } from "../../config";
import { CookieService } from "@/shared/service/cookie/CookieService";
import { processController, processStore } from "@/shared/service/proggress";
export var RefreshTokenResponseSchema = z.object({
    tokens: z.object({
        accessToken: z.string(),
        refreshToken: z.string(),
    }),
});
export var RefreshTokenResponse = createResponseSchema(RefreshTokenResponseSchema);
export var getToken = function () {
    var _a;
    var cookieAccessToken = CookieService.get(CONFIG_APP.ACCESS_TOKEN_COOKIE);
    var localStorageAccessToken = (_a = processStore.getState().auth) === null || _a === void 0 ? void 0 : _a.accessToken;
    return cookieAccessToken ? cookieAccessToken : localStorageAccessToken ? localStorageAccessToken : null;
};
export var getRefreshToken = function () {
    var _a;
    var cookieRefreshToken = CookieService.get(CONFIG_APP.REFRESH_TOKEN_COOKIE);
    var localStorageRefreshToken = (_a = processStore.getState().auth) === null || _a === void 0 ? void 0 : _a.refreshToken;
    return cookieRefreshToken ? cookieRefreshToken : localStorageRefreshToken ? localStorageRefreshToken : null;
};
export var fetchRefreshToken = function () {
    var url = new URL("".concat(CONFIG_APP.API_ENDPOINT, "/api/auth/refresh"));
    var refreshToken = getRefreshToken();
    return fetch(url, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer ".concat(refreshToken),
        },
    })
        .then(function (response) { return response.json(); })
        .then(function (data) { return RefreshTokenResponse.parseAsync(data); })
        .then(function (response) {
        if (response.status === 'success' && response.data) {
            var _a = response.data.tokens, accessToken = _a.accessToken, refreshToken_1 = _a.refreshToken;
            processController.updateTokens({ accessToken: accessToken, refreshToken: refreshToken_1 });
            CookieService.set(accessToken, CONFIG_APP.ACCESS_TOKEN_COOKIE);
            CookieService.set(refreshToken_1, CONFIG_APP.REFRESH_TOKEN_COOKIE);
            return accessToken;
        }
        else {
            CookieService.delete(CONFIG_APP.ACCESS_TOKEN_COOKIE);
            CookieService.delete(CONFIG_APP.REFRESH_TOKEN_COOKIE);
            return null;
        }
    });
};

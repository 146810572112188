var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var CookieService = {
    get: function (name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
            "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    set: function (value, name, options) {
        if (options === void 0) { options = {}; }
        options = __assign({ path: "/" }, options);
        //@ts-ignore
        if (options.expires instanceof Date) {
            //@ts-ignore
            options.expires = options.expires.toUTCString();
        }
        var updatedCookie = encodeURIComponent(name.trim()) + "=" + encodeURIComponent(value);
        for (var optionKey in options) {
            updatedCookie += "; " + optionKey;
            //@ts-ignore
            var optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }
        document.cookie = updatedCookie;
    },
    delete: function (name) {
        this.set('', name, {
            "max-age": -1,
        });
    },
};

import { notificationStore } from "./store";
var NotificationAdapter = /** @class */ (function () {
    function NotificationAdapter() {
    }
    NotificationAdapter.prototype.setNotification = function (notification) {
        var notificationList = notificationStore.getState().notificationList;
        if (notificationList.length)
            return;
        return notificationStore.setState({ notificationList: [notification] });
    };
    NotificationAdapter.prototype.resetNotification = function () {
        return notificationStore.setState({ notificationList: [] });
    };
    return NotificationAdapter;
}());
export { NotificationAdapter };

import { layoutStore } from "./store";
var LayoutAdapter = /** @class */ (function () {
    function LayoutAdapter() {
        this.isOpen = false;
    }
    LayoutAdapter.prototype.setSideMenuOpen = function () {
        return layoutStore.setState({ sideBarMenuState: true });
    };
    LayoutAdapter.prototype.setSideMenuClose = function () {
        return layoutStore.setState({ sideBarMenuState: false });
    };
    LayoutAdapter.prototype.setInitContacts = function (contacts) {
        layoutStore.setState({ contacts: contacts });
    };
    return LayoutAdapter;
}());
export { LayoutAdapter };

import { createStore } from "../../create-store";
var initState = {
    sideBarMenuState: false,
    contacts: {
        phone: "",
        email: "",
        contactVk: "",
        contactYoutube: "",
        contactInst: "",
        contactFacebook: "",
        contactAppStore: "",
        contactGooglePlay: "",
    }
};
export var layoutStore = createStore({
    initState: initState,
    persistName: 'layout-store',
});

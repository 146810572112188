import { CONFIG_APP } from "@/shared/config";
import { ProcessAdapter } from "../store/adapter";
import { CookieService } from "../../cookie/CookieService";
var ProcessController = /** @class */ (function () {
    function ProcessController() {
        this.adapter = new ProcessAdapter();
    }
    ProcessController.prototype.saveTemplateNods = function (nodes) {
        this.adapter.saveTemplateNods(this.fomattedProcessNodsStr(nodes));
    };
    ProcessController.prototype.updateAuth = function (auth) {
        this.adapter.updateAuth(auth);
    };
    ProcessController.prototype.updateTokens = function (tokens) {
        this.adapter.updateTokens(tokens);
    };
    ProcessController.prototype.spliteItem = function (nodesItem) {
        return nodesItem.split(' ')[0];
    };
    ProcessController.prototype.fomattedProcessNodsStr = function (nodesString) {
        var result = [];
        var mapSplitArray = new Map();
        mapSplitArray.set('splitArr', nodesString.split(' \n'));
        var splitNodesArray = mapSplitArray.get('splitArr');
        for (var i = 0; i < splitNodesArray.length; i++) {
            if (splitNodesArray[i].length < 4)
                continue;
            result.push(this.spliteItem(splitNodesArray[i]));
        }
        mapSplitArray.clear();
        return result;
    };
    ProcessController.prototype.getAdjacentSteps = function () {
        return this.adapter.getAdjacentSteps();
    };
    ProcessController.prototype.getCurrentAndPrevStep = function () {
        return this.adapter.getCurrentAndPrevStep();
    };
    ProcessController.prototype.searchStep = function (value) {
        return this.adapter.searchStep(value);
    };
    ProcessController.prototype.resetProcess = function () {
        return this.adapter.resetProcess();
    };
    ProcessController.prototype.setSpeed = function (value) {
        return this.adapter.setSpeed(value);
    };
    ProcessController.prototype.setPlay = function (value) {
        return this.adapter.setPlay(value);
    };
    ProcessController.prototype.changeStep = function (value) {
        return this.adapter.changeStep(value);
    };
    ProcessController.prototype.getStepsVoiceStrings = function () {
        return this.adapter.getStepsVoiceStrings();
    };
    ProcessController.prototype.saveAuth = function (auth) {
        CookieService.set(auth.accessToken, "".concat(CONFIG_APP.ACCESS_TOKEN_COOKIE));
        CookieService.set(auth.refreshToken, "".concat(CONFIG_APP.REFRESH_TOKEN_COOKIE));
        return this.adapter.saveAuth(auth);
    };
    ProcessController.prototype.toggleMainInsturction = function (value) {
        return this.adapter.toggleMainInsturction(value);
    };
    ProcessController.prototype.closeProcessInsturction = function (value) {
        return this.adapter.toggleProcessInsturction(value);
    };
    ProcessController.prototype.getRefreshToken = function () {
        return this.adapter.getRefreshToken();
    };
    return ProcessController;
}());
export { ProcessController };
export var processController = new ProcessController();
